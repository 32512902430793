import { ReactNode, Suspense, lazy } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import MainLayout from '@/layouts/Main'
// import Home from '@/pages/HomePage'

const HomePage = lazy(
  () => import(/* webpackChunkName: "home-page" */ '@/pages/HomePage'),
)
const TelegramBot = lazy(
  () => import(/* webpackChunkName: "telegram-bot" */ '@/pages/TelegramBot'),
)
const AlphaScanner = lazy(
  () => import(/* webpackChunkName: "alpha-scanner" */ '@/pages/AlphaScanner'),
)
const Earn = lazy(() => import(/* webpackChunkName: "earn" */ '@/pages/Earn'))
const Dashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard'),
)

const SolanaChangeSite = lazy(
  () =>
    import(
      /* webpackChunkName: "solana-place-change-site" */ '@/pages/solana-place/change-site'
    ),
)
const BaseNft = lazy(
  () => import(/* webpackChunkName: "base-nft" */ '@/pages/base-place/nft'),
)

const BaseMint = lazy(
  () => import(/* webpackChunkName: "base-mint" */ '@/pages/base-place/mint'),
)

const NotFound = lazy(
  () => import(/* webpackChunkName: "not-found" */ '@/pages/NotFound/NotFound'),
)
const Routes = ({ children }: { children?: ReactNode }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          // key={`route-route-config-${routeConfigIndex}`}
          exact
          path={[
            '/',
            '/telegram-bot',
            '/alpha-scanner',
            '/earn',
            '/dashboard',
            '/solana-place',
            '/solana-place/referral',
            '/solana-place/mint',
            '/base-place/nft',
            '/base-place/mint',
          ]}
        >
          <MainLayout>
            <Suspense fallback={null}>
              <Route exact path="/" component={HomePage}></Route>
              <Route exact path="/telegram-bot" component={TelegramBot}></Route>
              <Route
                exact
                path="/alpha-scanner"
                component={AlphaScanner}
              ></Route>
              <Route exact path="/earn" component={Earn}></Route>
              <Route exact path="/dashboard" component={Dashboard}></Route>
              <Route
                exact
                path="/solana-place"
                component={SolanaChangeSite}
              ></Route>
              <Route
                exact
                path="/solana-place/referral"
                component={SolanaChangeSite}
              ></Route>
              <Route
                exact
                path="/solana-place/mint"
                component={SolanaChangeSite}
              ></Route>
              <Route exact path="/base-place/mint" component={BaseMint}></Route>
              <Route exact path="/base-place/nft" component={BaseNft}></Route>
            </Suspense>
          </MainLayout>
        </Route>
        <Route path="*">
          <MainLayout>
            <Suspense fallback={null}>
              <Route path="*" component={NotFound}></Route>
            </Suspense>
          </MainLayout>
        </Route>
      </Switch>
      {children}
    </BrowserRouter>
  )
}
export default Routes
