import { useState } from 'react'

export const useSnackSystem = () => {
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  // const snackMessageQueue = useRef([])
  const onCloseHandler = () => {
    setIsOpenSnack(false)
    setSnackMessage('')
  }
  // TODO: need finish
  // const addMessage = ({ message }: { message: string }) => {
  //   snackMessageQueue.current.push(message)
  // }
  // useEffect(() => {}, [])
  return {
    isOpenSnack,
    setIsOpenSnack,
    snackMessage,
    setSnackMessage,
    onCloseHandler,
  }
}
