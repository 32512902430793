import IconTelegram from '@/assets/images/icons/telegram.svg'
import IconGitbook from '@/assets/images/icons/gitbook.svg'
import IconMedium from '@/assets/images/icons/medium.svg'
import IconTwitter from '@/assets/images/icons/twitter.svg'
import IconYoutube from '@/assets/images/icons/youtube.svg'
const MainFooter = () => {
  return (
    <div className="mt-80px">
      <div className="flex justify-center">
        {/* <!-- white paper --> */}
        <a
          className="footer-icon-link"
          href="https://alpha-radar-bot.gitbook.io/doc/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={IconGitbook}
            alt="gitbook link icon"
          />
        </a>
        {/* <!-- twitter --> */}
        <a
          className="footer-icon-link"
          href="https://twitter.com/alpharadarbot"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={IconTwitter}
            alt="x link icon"
          />
        </a>
        {/* <!-- telegram --> */}
        <a
          className="footer-icon-link"
          href="https://t.me/alpharadarbot_chat"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={IconTelegram}
            alt="telegram link icon"
          />
        </a>
        {/* <!-- youtube --> */}
        <a
          className="footer-icon-link"
          href="https://www.youtube.com/channel/UCAP1feFzD-ej3snXqZck6XQ"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={IconYoutube}
            alt="youtube link icon"
          />
        </a>
        {/* <!-- medium --> */}
        <a
          className="footer-icon-link"
          href="https://medium.com/@alpharadarbot"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ width: '32px', height: '32px' }}
            src={IconMedium}
            alt="medium link icon"
          />
        </a>
      </div>
      <div className="copy-right mt-16px">
        Alpha Radar Bot 2023© - All Rights Reserved.
      </div>
    </div>
  )
}
export default MainFooter
