import Snackbar from '@mui/material/Snackbar'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSnackSystem } from './useSnackSystem'

export const SnackSystem = () => {
  const { isOpenSnack, onCloseHandler, snackMessage } = useSnackSystem()
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  useEffect(() => {
    if (isOpen === isOpenSnack) return
    setIsOpen(isOpenSnack)
  }, [isOpenSnack])
  useEffect(() => {
    if (message === snackMessage) return
    setMessage(snackMessage)
  }, [isOpenSnack])
  return (
    <div>
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={onCloseHandler}
        message={message}
      />
    </div>
  )
}
