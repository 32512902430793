import { ReactNode } from 'react'
import './main.css'
import MainHeader from './components/MainHeader'
import MainFooter from './components/MainFooter'
import ImageMainStarBackground from '@/assets/images/main-star-background.svg'
interface LayoutProps {
  children: ReactNode
}
const Main = ({ children }: LayoutProps) => {
  return (
    <div className="main-layout">
      <div className="root-container">
        <MainHeader />
        <img
          className="root-background-fix-image"
          src={ImageMainStarBackground}
          alt="background"
        />
        <div className="root-content-container text-center">
          <div className="main-container">{children}</div>
          <MainFooter />
        </div>
      </div>
    </div>
  )
}
export default Main
