import { parseKeyToCamelCase, parseKeyToSnakeCase } from '@/helper'
import {
  request,
  apiErrorMsg,
  ApiService,
} from '@/networks/http/apps/alpha/api-helper'

// message: 'address:account_id'
export const bindAccount = (payload: {
  message: string
  singnature: string
}): ApiService<boolean> => {
  return request
    .post('/bind', parseKeyToSnakeCase(payload))
    .then((res) => ({
      isError: false,
      value: parseKeyToCamelCase(res.data.user),
    }))
    .catch((err) => {
      return { isError: true, value: err.response?.data?.error }
    })
}
