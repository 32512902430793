import _camelCase from 'lodash/camelCase'
import _snakeCase from 'lodash/snakeCase'

// parse object key to camelcase, include deep key and object in array.
export const parseKeyToCamelCase = (
  data,
  skipCompareFn = (data) => typeof data !== 'object',
) => {
  if (skipCompareFn(data) || data === null) return data
  if (Array.isArray(data)) return data.map((item) => parseKeyToCamelCase(item))
  const newData = {}
  for (const key in data) {
    const newKey = _camelCase(key)
    newData[newKey] = parseKeyToCamelCase(data[key])
  }
  return newData
}
// parse object key to snackCase, include deep key and object in array.
export const parseKeyToSnakeCase = (
  data,
  skipCompareFn = (item) => typeof item !== 'object',
) => {
  if (skipCompareFn(data) || data === null) return data
  if (Array.isArray(data))
    return data.map((item) => parseKeyToSnakeCase(item, skipCompareFn))
  const newData = {}
  for (const key in data) {
    const newKey = _snakeCase(key)
    newData[newKey] = parseKeyToSnakeCase(data[key], skipCompareFn)
  }
  return newData
}
