import axios, { AxiosInstance } from 'axios'
// import { isDev } from '@otsofintech/sofinx-ui/lib/helper'
// import { errorCodeMap } from '@otsofintech/sofinx-ui/lib/data'
// import i18n from '@/assets/locales/i18n'
// import { showMessage } from '@/lib/useMessage'

export type ApiService<T, U = Error> = Promise<
  | { readonly isError: false; readonly value: T }
  | { readonly isError: true; readonly value: U }
>

// type Error = {
//   code: keyof typeof errorCodeMap
//   message: string
//   details?: string[]
// }

const isDev = () => process.env.REACT_APP_MODE === 'development'

type Environment = 'test' | 'develop'

// api url
const proxyRouteMap = {
  test: '/local-proxy-api-test',
  development: '/local-proxy-api-develop',
}
const proxyRoute =
  proxyRouteMap[process.env.REACT_APP_MODE as Environment] || 'proxy-error'
// const productionHost =
//   document.getElementsByName('api-host')[0]?.getAttribute('content') || ''
// const productionRoute = `https://${productionHost}`
// const BASE_URL = isDev() ? proxyRoute : productionRoute
// TODO: need change when prod
// const productionRoute = 'https://test-api.alpharadar.ai'
const productionRoute =
  window.location.hostname === 'alpharadarbot.xyz'
    ? 'https://tgbot.alpharadarbot.xyz'
    : 'https://test-api.alpharadar.ai'

const BASE_URL = isDev() ? proxyRoute : productionRoute

// site token
const developSiteToken = process.env.REACT_APP_SITE_TOKEN
/**
 * TODO: 目前與後端並未實作真實 token，待更新
 */
const productionSiteToken =
  'Gaear4dS1kadLa5' ||
  document.getElementsByName('site-token')[0]?.getAttribute('content')
const SITE_TOKEN = isDev() ? developSiteToken : productionSiteToken

// axios.defaults.headers.common['Token'] = SITE_TOKEN

const getHeaders = () => {
  const headers = {
    token: SITE_TOKEN,
  }
  if (isDev() && navigator.vendor === 'Apple Computer, Inc.')
    headers['Authentication-Info'] = process.env.REACT_APP_SOFINX_USERID

  return headers
}
export const request = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: getHeaders(),
})

export function apiErrorMsg(serviceName: string, error: Error) {
  if (isDev()) console.log(serviceName)
  // showMessage({
  //   icon: 'error',
  //   message: i18n.t(errorCodeMap[error?.code || ''] || 'apiError'),
  // })
}

// Zion 要求測試用
// if (isDev('btsb.sofinx.otso-dev.com'))
//   (window as typeof window & { axios: AxiosInstance }).axios = request
