/* eslint-disable jsx-a11y/anchor-is-valid */
import './style.css'
import ImageLongLogo from '@/assets/images/long-logo.svg'
import { Link, useLocation } from 'react-router-dom'
import IconTelegramOutline from '@/assets/images/icons/telegram-outline.svg'
import IconWalletOutline from '@/assets/images/icons/wallet-outline.svg'
import ImgBlueStarIcon from '@/assets/images/blue-star-icon.svg'
import { useWeb3React } from '@web3-react/core'
import {
  activateInjectedProvider,
  getInjectedHandler,
  switchNetwork,
} from '@/lib/wallets'
import { useEffect, useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { bindAccount } from '@/networks/http/apps/alpha/user'
import { ethers } from 'ethers'
import Snackbar from '@mui/material/Snackbar'
import IconMenu from '@/assets/images/icons/menu.svg'
import IconCancel from '@/assets/images/icons/cancel.svg'
import { BASE_MAINNET, BASE_SEPOLIA, DEFAULT_CHAIN_ID } from '@/config/chains'
import ImgSolanaPlaceLink from '@/assets/images/pages/solana-place/solana-place-link.svg'

// TODO: routes need be config
const MainHeader = () => {
  const location = useLocation()
  const { active, account, deactivate, activate, chainId } = useWeb3React()
  console.log({ active })
  const [isOpenConnectTgDialog, setIsOpenConnectTgDialog] = useState(false)
  const [isOpenSnack, setIsOpenSnack] = useState(false)
  const [snackMessage, setSnackMessage] = useState('')
  const [isOpenMdMenu, setIsOpenMdMenu] = useState(false)

  const [tgChatId, setTgChatId] = useState('')
  const isBasePlace = useMemo(() => {
    return location.pathname.includes('base-place')
  }, [location])
  // TODO: make real
  const headerMenuItems = useMemo(() => {
    return [
      {
        id: 'home-page',
        label: 'Home',
        to: '/',
      },
      {
        id: 'telegram-bot',
        label: 'TG Bot',
        to: '/telegram-bot',
      },
      {
        id: 'alpha-scanner',
        label: 'Scanner',
        to: '/alpha-scanner',
      },
      // {
      //   id: 'dashboard',
      //   label: 'Dashboard',
      //   to: '/dashboard',
      // },
      {
        id: 'arbot-staking',
        label: 'Staking',
        to: '/earn',
      },
      {
        id: 'base-nft',
        label: 'Base NFT',
        to: '/base-place/mint',
      },
      // {
      //   id: 'solana-place',
      //   label: 'Solana Place',
      //   to: '/solana-place',
      // },
    ]
  }, [])
  const connectInjectedWallet = getInjectedHandler(activate, deactivate)
  // TODO: boolean string
  const attemptActivateWallet = async (providerName) => {
    localStorage.setItem('SHOULD_EAGER_CONNECT_LOCALSTORAGE_KEY', 'true')
    localStorage.setItem('CURRENT_PROVIDER_LOCALSTORAGE_KEY', providerName)
    activateInjectedProvider(providerName)
    await connectInjectedWallet()
  }

  const activateMetaMask = async () => {
    try {
      // if (!hasMetaMaskWalletExtension()) {
      //   helperToast.error(
      //     <div>
      //       <Trans>MetaMask not detected.</Trans>
      //       <br />
      //       <br />
      //       {userOnMobileDevice ? (
      //         <Trans>
      //           <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink>, and use ALSO with its built-in
      //           browser.
      //         </Trans>
      //       ) : (
      //         <Trans>
      //           <ExternalLink href="https://metamask.io">Install MetaMask</ExternalLink> to start using ALSO.
      //         </Trans>
      //       )}
      //     </div>
      //   );
      //   return false;
      // }
      await switchNetwork(isBasePlace ? BASE_MAINNET : DEFAULT_CHAIN_ID)
      await attemptActivateWallet('MetaMask')
    } catch (error) {
      console.error('activateMetaMask', error)
      setSnackMessage('error')
      setIsOpenSnack(true)
    }
  }
  const checkConnect = async () => {
    try {
      if (!active && window.ethereum) {
        const result = await window.ethereum.request({ method: 'eth_accounts' })
        if (result && result[0]) {
          await activateMetaMask()
        }
      }
    } catch (error) {
      console.error('main header: ', error)
    }
  }
  const signEth = async () => {
    try {
      const getProvider = (
        network: ethers.Networkish | ethers.WebSocketLike,
      ) => {
        let provider
        if (window.ethereum == null) {
          // If MetaMask is not installed, we use the default provider,
          // which is backed by a variety of third-party services (such
          // as INFURA). They do not have private keys installed so are
          // only have read-only access
          console.log('MetaMask not installed; using read-only defaults')
          provider = ethers.getDefaultProvider(network)
        } else {
          // Connect to the MetaMask EIP-1193 object. This is a standard
          // protocol that allows Ethers access to make all read-only
          // requests through MetaMask.
          provider = new ethers.BrowserProvider(window.ethereum)

          // It also provides an opportunity to request access to write
          // operations, which will be performed by the private key
          // that MetaMask manages for the user.
        }
        return provider
      }
      const signer = await getProvider('mainnet').getSigner()
      if (!signer) {
        console.log('lost signer', signer)
        return
      }
      const message = `${account}:${tgChatId}`
      const messageBytes = ethers.toUtf8Bytes(message)

      const signature = await signer.signMessage(messageBytes)
      const result = await bindAccount({
        message: message,
        singnature: signature,
      })
      if (result.isError) {
        setSnackMessage('error')
        setIsOpenSnack(true)
        return
      }
      setIsOpenConnectTgDialog(false)

      setSnackMessage('success')
      setIsOpenSnack(true)
    } catch (error) {
      console.error('sign func:', error)
      setSnackMessage('error')
      setIsOpenSnack(true)
    }
  }
  const sign = async () => {
    await signEth()
  }
  useEffect(() => {
    try {
      if (isOpenConnectTgDialog && !active) {
        activateMetaMask()
      }
    } catch (error) {
      console.error('tg dialog: ', error)
    }
  }, [isOpenConnectTgDialog])
  const changeNetwork = async () => {
    try {
      if (!active) return
      if (isBasePlace) {
        if (chainId === BASE_MAINNET) return
        await switchNetwork(BASE_MAINNET)
        return
      }
      if (chainId === DEFAULT_CHAIN_ID) return
      await switchNetwork(DEFAULT_CHAIN_ID)
    } catch (error) {
      console.log({ error })
      console.log('code', error.code)
    }
  }
  useEffect(() => {
    changeNetwork()
  }, [chainId, active, isBasePlace])
  useEffect(() => {
    checkConnect()
  }, [])
  return (
    <div className="header">
      <Link to="/">
        <img className="header-logo" src={ImageLongLogo} alt="logo" />
      </Link>
      <div className="flex w-full md-hidden">
        <div className="flex items-center ml-40px">
          {headerMenuItems.map((headerMenuItem) => (
            <Link
              key={`headerMenuItems-${headerMenuItem.id}`}
              to={headerMenuItem.to}
              className={`header-link-block header-link ${
                location.pathname === headerMenuItem.to ? 'active' : ''
              }`}
            >
              {headerMenuItem.label}
            </Link>
          ))}
          {/* boost */}
          {/* <div className="header-link-block flex">
            <a href="#" className="header-link disabled">
              Alpha Boost
            </a>
            <img
              className="ml-4px"
              src={ImageHeaderLinkComingSoon}
              alt="coming soon"
            />
          </div> */}
        </div>
        <div className="ml-auto flex items-center gap-16px">
          <a
            href="https://solana.alpharadarbot.xyz/mint"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="block"
              src={ImgSolanaPlaceLink}
              alt="Alpha Radar On Solana"
            />
          </a>
          <button
            style={{ padding: '1px' }}
            className="button-linear-back"
            onClick={() => setIsOpenConnectTgDialog(true)}
          >
            <div className="button-linear-front">
              <div className="header-button flex items-center text-white">
                <img
                  style={{ width: '24px', height: '24px' }}
                  src={IconTelegramOutline}
                  alt="telegram"
                />
                <div
                  style={{ lineHeight: '32px' }}
                  className="text-14px ml-8px"
                >
                  Link Telegram
                </div>
              </div>
            </div>
          </button>
          {active ? (
            <div style={{ padding: '1px' }} className="button-linear-back">
              <div className="button-linear-front">
                <div className="header-button flex items-center text-white">
                  <div className="header-blue-star-rounded-block">
                    <img
                      style={{ width: '12px', height: '12px' }}
                      src={ImgBlueStarIcon}
                      alt="star"
                    />
                  </div>

                  <div
                    style={{ lineHeight: '32px' }}
                    className="text-14px ml-8px"
                  >
                    {`${account.substring(0, 6)}...${account.substring(
                      account.length - 4,
                      account.length,
                    )}`}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button
              style={{ padding: '1px' }}
              className="button-linear-back  ml-16px"
              onClick={activateMetaMask}
            >
              <div className="button-linear-front">
                <div className="header-button flex items-center text-white">
                  <img
                    style={{ width: '24px', height: '24px' }}
                    src={IconWalletOutline}
                    alt="wallet"
                  />
                  <div
                    style={{ lineHeight: '32px' }}
                    className="text-14px ml-8px"
                  >
                    Connect Wallet
                  </div>
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
      <button
        onClick={() => setIsOpenMdMenu(!isOpenMdMenu)}
        className="hidden md-flex ml-auto items-center"
      >
        <img src={isOpenMdMenu ? IconCancel : IconMenu} alt="menu toggle" />
      </button>
      {/* mobile */}
      <div
        style={{
          transform: isOpenMdMenu ? 'translateX(0)' : 'translateX(100%)',
        }}
        className="header-md-menu-block"
      >
        {headerMenuItems.map((headerMenuItem) => (
          <Link
            key={`headerMenuItems-md-${headerMenuItem.id}`}
            onClick={() => setIsOpenMdMenu(false)}
            to={headerMenuItem.to}
            className={`text-24px header-md-menu-item-block ${
              location.pathname === headerMenuItem.to ? 'active' : ''
            }`}
          >
            {headerMenuItem.label}
          </Link>
        ))}
        <a
          key={'headerMenuItems-md-solana-place'}
          onClick={() => setIsOpenMdMenu(false)}
          href="https://solana.alpharadarbot.xyz/mint"
          className="text-24px header-md-menu-item-block"
          target="_blank"
          rel="noreferrer"
        >
          Alpha Radar On Solana
        </a>
        <button
          onClick={() => {
            setIsOpenConnectTgDialog(true)
            setIsOpenMdMenu(false)
          }}
          className={'text-24px header-md-menu-item-block text-white'}
        >
          Link Telegram
        </button>
        {!active && (
          <button
            onClick={() => {
              activateMetaMask()
              setIsOpenMdMenu(false)
            }}
            className={'text-24px header-md-menu-item-block text-white'}
          >
            Connect Wallet
          </button>
        )}
      </div>
      <Dialog
        open={isOpenConnectTgDialog}
        onClose={() => setIsOpenConnectTgDialog(false)}
        PaperProps={{
          style: {
            padding: '24px 32px',
            width: '480px',
            background: '#24262A',
            borderRadius: '16px',
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setIsOpenConnectTgDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ color: '#cccccc' }}>
          <div className="text-18px font-bold">Link Telegram</div>
          <div className="mt-16px text-14px font-medium">
            Once you've linked your Telegram account and confirmed that you have
            more than 50000 $ARBOT tokens, you can start using the Alpha Radar
            Bot.
            <br />
            To obtain a chat ID, enter the command "/chat_id" in Alpha Radar
            Bot.
          </div>
          <div className="mt-16px">
            <TextField
              className="custom-border-mui-outline-input"
              sx={{
                input: {
                  color: '#ffffff',
                },
              }}
              fullWidth
              value={tgChatId}
              onChange={(e) => setTgChatId(e.target.value)}
              placeholder="Chat ID"
            />
          </div>
          <div className="mt-16px flex justify-end">
            <Button
              style={{ padding: '12px 24px', backgroundColor: '#5E93DB' }}
              variant="contained"
              onClick={sign}
              disabled={!account || !tgChatId}
            >
              <div className="text-16px font-semibold">Link</div>
            </Button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={isOpenSnack}
        autoHideDuration={3000}
        onClose={() => setIsOpenSnack(false)}
        message={snackMessage}
      />
    </div>
  )
}
export default MainHeader
