import { useEffect, useState } from 'react'
import { useEagerConnect, useInactiveListener } from './lib/wallets'
import Routes from './routes'
import { Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useLocation } from 'react-router-dom'
// import { ETH_GOERLI } from './config/chains'
import { SnackSystem } from './layouts/SnackSystem'
import { useChainId } from './lib/chains'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  return library
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  // TODO: change to mainnet on prod
  const { chainId } = useChainId()
  const { connector, library, deactivate, activate, active } = useWeb3React()

  const [activatingConnector, setActivatingConnector] = useState()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector, chainId])
  const triedEager = useEagerConnect(setActivatingConnector)
  useInactiveListener(!triedEager || !!activatingConnector)

  useEffect(() => {
    dayjs.extend(utc)
  }, [])

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Routes>
        <ScrollToTop />
        <SnackSystem />
      </Routes>
    </Web3ReactProvider>
  )
}

export default App
